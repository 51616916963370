@import "_variables.scss";
.yn-calls-row {
  height: $yn-calls-row-height;
  margin: 0 0.8em;
}
.yn-calls-panel {
  height: $yn-calls-panel-height;
  align-content: flex-start;
}
.yn-calls-panel-title {
  height: $yn-header-title-height;
  padding-top: 0.8em;
}
