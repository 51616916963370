@import '_variables.scss';

html,
body,
#yn,
.yn-page {
  height: 100%;
}

.yn-page {
  display: flex;
  flex-flow: row wrap;
  min-width: 993px;
}

.yn-header {
  flex: 1 100%;
  display: flex;
  height: $header-height;
}

.yn-footer {
  flex: 1 100%;
  height: $footer-height;
}

.yn-main,
.yn-left,
.yn-right {
  margin-top: $margin;
  margin-bottom: $margin;
  height: $main-height;
  min-height: 300px;
}

.yn-left {
  flex: 0 $left-column-width;
  margin-left: $margin;
  margin-right: 0;
  width: $left-column-width;
}

.yn-main {
  margin: $margin;
  flex: 1;
}

.yn-right {
  flex: 0 $right-column-width;
  margin-left: 0;
  margin-right: $margin;
  width: $right-column-width;
}
.yn-left-width {
  width: $left-column-width;
}
.yn-section {
  height: calc(100% - #{$yn-section-title-height});
  padding: 1rem;
}
.yn-section-scrolling {
  height: calc(100% - #{$yn-section-title-height});
  padding: 1rem;
  overflow-y: auto;
}
.yn-section-with-nav {
  height: calc(
    100% - (#{$yn-section-title-height} + #{$yn-secondary-nav-height} + 6px)
  );
  margin-top: 6px;
  padding: 1rem;
  overflow-y: hidden;
}
.yn-section-with-nav-scrolling {
  height: calc(
    100% - (#{$yn-section-title-height} + #{$yn-secondary-nav-height} + 6px)
  );
  margin-top: 6px;
  padding: 1rem;
  overflow-y: auto;
}
.yn-section-with-nav-and-filter-scrolling {
  height: calc(
    100% - (#{$yn-section-title-height} + #{$yn-secondary-nav-height} + 6px)
  );
  margin-top: 6px;
  padding: 1rem;
  overflow-y: auto;
}
.yn-form-scrolling-and-header {
  max-height: calc(100% - 3.2rem);
  overflow-y: auto;
}
.yn-form-scrolling {
  max-height: calc(100%);
  overflow-y: auto;
}
.yn-chat-windows-list {
  position: fixed;
  bottom: 0px;
  //height: $yn-chat-windows-list-height;
  left: $yn-chat-windows-list-position-left;
  right: $yn-chat-windows-list-position-right;
  z-index: 960;
}
.yn-col-room {
  font-size: 0.9em;
  position: relative;
  min-height: 1px;
  padding-right: 0.9em;
  padding-left: 0.9em;
  float: left;
  width: 25%;
  input[type='checkbox'] {
    margin-left: -15px;
  }
}

// sm
@media all and (min-width: 576px) {
  .yn-login-form {
    max-width: 450px;
    border-radius: 0.25rem;
  }
}

// lg
@media all and (min-width: 992px) {
  .yn-left {
    flex: 0 $left-column-width-lg;
    width: $left-column-width-lg;
  }
  .yn-main {
    flex: 1;
  }
  .yn-right {
    flex: 0 $right-column-width-lg;
    width: $right-column-width-lg;
  }
  .yn-left-width {
    width: $left-column-width-lg;
  }
  .yn-groups-panel {
    width: $right-column-width-lg;
  }
  .yn-col-room {
    width: 16%;
  }
  .yn-chat-windows-list {
    left: $yn-chat-windows-list-position-left-lg;
    right: $yn-chat-windows-list-position-right-lg;
  }
}

// xl
@media all and (min-width: 1200px) {
  .yn-left {
    flex: 0 $left-column-width-xl;
    width: $left-column-width-xl;
  }
  .yn-right {
    flex: 0 $right-column-width-xl;
    width: $right-column-width-xl;
  }
  .yn-left-width {
    width: $left-column-width-xl;
  }
  .yn-groups-panel {
    width: $right-column-width-xl;
  }
  .yn-col-room {
    width: 10%;
  }
  .yn-chat-windows-list {
    left: $yn-chat-windows-list-position-left-xl;
    right: $yn-chat-windows-list-position-right-xl;
  }
}
