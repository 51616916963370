@import "_variables.scss";

%yn-message {
  display: block;
  position: absolute;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  content: "";
}

.yn-message-sent {
  border: 1px solid $yn-blue-light;
}

.yn-message-received {
  border: 1px solid $yn-gray-400;
}

.yn-message-sent:after {
  @extend %yn-message;
  border-left: 7px solid $yn-blue-lighter;
}

.yn-message-received:after {
  @extend %yn-message;
  border-right: 7px solid $yn-gray-200;
}

.yn-chat-windows-list .yn-message-sent:after {
  top: 5px;
  right: 23px;
}

.yn-chat-windows-list .yn-message-received:after {
  top: 5px;
  left: 23px;
}

#vocalAssistantPanel .yn-message-sent:after {
  top: 0px;
  right: -7px;
}

#vocalAssistantPanel .yn-message-received:after {
  top: 0px;
  left: -6px;
}

.yn-comment-list .yn-message-received {
  margin-left: 50px;
  background: var(--yn-gray-200);
  .yn-comment-datetime {
    color: var(--yn-gray-600)
  }
  .yn-comment-creator {
    color: var(--yn-gray-600);
    font-size: '0.8rem';
  }
  &:after {
    top: 5px;
    left: 43px;
  }
}

.yn-comment-list .yn-message-sent {
  margin-left: 50px;
  background: var(--yn-blue-lighter);
  .yn-comment-datetime {
    color: var(--yn-blue)
  }
  .yn-comment-creator {
    color: var(--yn-blue);
    font-size: '0.8rem';
  }
  &:after {
    border-right: 7px solid $yn-blue-lighter;
    border-left: initial;
    top: 5px;
    left: 43px;
  }
}

.yn-comment-text {
  word-break: break-word;
  white-space: pre-wrap;
  border-top: 1px solid var(--yn-gray-400);
}

.yn-chat-no-conversations, .yn-chat-no-searched-messages {
  display: none;
}

.yn-chat-conversations-table tr:first-child.yn-chat-no-conversations,
.yn-chat-search-messages-table tr:first-child.yn-chat-no-searched-messages {
  display: block;
}

.yn-chat-removed-room {
  position:absolute;
  top: 15px;
  left: 3px;
}

.yn-chat-removed-room:after {
  font-size:7px;
  opacity:0.7;
}

.yn-chat-removed-sender {
  position:absolute;
  top: 5px;
  left: -5px;
}

.yn-chat-removed-sender:after {
  font-size:4px;
  opacity:0.3;
}

.yn-chat-removed-room:after, .yn-chat-removed-sender:after {
  content:"REMOVED";
  position:absolute;
  z-index:1;
  font-family:Arial,sans-serif;
  -webkit-transform: rotate(-45deg); /* Safari */
  -moz-transform: rotate(-45deg); /* Firefox */
  -ms-transform: rotate(-45deg); /* IE */
  -o-transform: rotate(-45deg); /* Opera */
  transform: rotate(-45deg);
  color:#c00;
  background:#fff;
  border:solid 4px #c00;
  padding:2px;
  border-radius:5px;
  zoom:1;
  filter:alpha(opacity=20);
  -webkit-text-shadow: 0 0 2px #c00;
  text-shadow: 0 0 2px #c00;
  box-shadow: 0 0 2px #c00;
}