$yn-blue: #0098e5;
$yn-blue-rgb: '0, 152, 229';
$yn-blue-light: #a2d2ea;
$yn-blue-lighter: #d8e9f2;
$yn-blue-dark: #0076b2;
$yn-blue-darker: #005d8c;
$yn-blue-darkest: #002a3f;
$yn-draggable-background: #0098e540;
$yn-favorites-group-color: #f1c40f;
$yn-highlight: yellow;
$yn-calendar-festivity: #efefef;
$yn-success-background-color: #d4edda;
$yn-danger-background-color: #f8d7da;
$yn-info-background-color: #d1ecf1;

$yn-gray-100: #f7f7f7;
$yn-gray-200: #f1f1f1;
$yn-gray-300: #e0e1e5;
$yn-gray-400: #cfd0d6;
$yn-gray-500: #b3b7bc;
$yn-gray-600: #72787c;
$yn-gray-700: #4e5256;
$yn-gray-800: #373b3f;
$yn-gray-900: #222528;

$yn-light-red: 'rgba(220, 53, 69, .4)';
$yn-light-green: 'rgba(40, 167, 69, .4)';
$yn-light-yellow: 'rgba(255, 249, 84, .4)';

$body-bg: $yn-gray-200;
$body-color: $yn-blue-darker;
$yn-border-color: $yn-gray-300;
$yn-input-focus-border-color: lighten($yn-blue, 25%);
$yn-input-focus-box-shadow: '0 0 0 .2rem rgba(#{$yn-blue-rgb}, .25)';

$font-family: 'Open sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';

$header-height: 3.5rem;
$footer-height: 2.5rem;
$margin: 0.3rem;
$main-height: calc(
  100% - (#{$header-height} + #{$footer-height} + (#{$margin} * 2))
);
$yn-section-title-height: 42px;
$yn-secondary-nav-height:40px;
$yn-header-title-height: 40px;
$yn-calls-row-height: calc(50% - #{$margin});
$yn-calls-panel-height: calc(
  100% - #{$yn-header-title-height} - (#{$margin} * 2)
);

$left-column-width: 13em;
$right-column-width: 15em;

$left-column-width-lg: 14em;
$right-column-width-lg: 16em;

$left-column-width-xl: 15em;
$right-column-width-xl: 20em;

$yn-chat-windows-list-height: calc(100% * 0.60);
$yn-chat-windows-list-position-left: $left-column-width;
$yn-chat-windows-list-position-right: $right-column-width;
$yn-chat-windows-list-position-left-lg: calc(
  #{$left-column-width-lg} + (#{$margin} * 2)
);
$yn-chat-windows-list-position-right-lg: calc(
  #{$right-column-width-lg} + (#{$margin} * 2)
);
$yn-chat-windows-list-position-left-xl: calc(
  #{$left-column-width-xl} + (#{$margin} * 2)
);
$yn-chat-windows-list-position-right-xl: calc(
  #{$right-column-width-xl} + (#{$margin} * 2)
);

$abook-show-header-height-xl: 12.5em;
$abook-show-header-height-lg: 12.5em;
//$abook-show-list-height: calc(100% - (#{$abook-show-header-height}));
