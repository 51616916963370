@import "_variables.scss";

.yn-calendar-highlight {
  background-color: $yn-highlight;
}
.yn-calendar-festivity {
  background-color: $yn-calendar-festivity;
}
.fc-event {
  color: var(--yn-dark-blue) !important;
}
.fc-day, .fc-day-top, .fc-day-number {
  cursor: pointer
}
.fc-event.yn-calendar-synchronized {
  color: white !important;
}
.fc-event.yn-vacation-event {
  color: white !important;
}
.fc-event.yn-pending-approving { // new vacation to be approved
  opacity: 0.7 !important;
}
.yn-rejected-approving { // new vacation rejected
  text-decoration: line-through !important;
}
.fc-event.yn-pending-deleting { // approved vacation is asked for deleting
  opacity: 0.7 !important;
}
.yn-pending-deleting {
  text-decoration: line-through !important;
}
.yn-rejected-deleting {
}

.fc-view, .fc-view>table {
  z-index: 0 !important;
}
