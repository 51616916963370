@import "_variables.scss";

.call-ringing {
  background-color: white;
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform: translateZ(0);
}

@keyframes pulse {
  0% {
    background-color: white;
  }
  50% {
    background-color: $yn-info-background-color;
  }
  100% {
    background-color: white;
  }
}