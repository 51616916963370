@import 'bootstrap/bootstrap.scss';
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/list/main.css';
@import '_root.scss';
@import '_layout.scss';
@import '_layoutCalls.scss';
@import '_layoutAbook.scss';
@import '_dropdown.scss';
@import '_navbar.scss';
@import '_fullCalendar.scss';
@import '_presences.scss';
@import '_textEditor.scss';
@import '_keyPad.scss';
@import '_messages.scss';
@import '_videocall.scss';
@import '_animations.scss';
@import '_abookTags.scss';
@import '_tickets.scss';
@import '_toastify.scss';
@import '_tabs.scss';
@import '_reactPdf.scss';

@media print {
  html, body {
    height: auto; /* Use 100% here to support printing more than a single page*/
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}

svg {
  pointer-events: none;
}

div.popover {
  z-index: 1050;
}
input,
button,
select,
optgroup,
textarea,
audio,
div {
  outline: none !important;
}

a:focus,
tbody:focus,
tfoot:focus,
tr:focus,
span:focus {
  outline: none !important;
}

.DayPicker {
  font-size: 0.85rem !important;
  line-height: 1.3 !important;
}

.yn-td-bordered-red {
  border-left: 4px solid var(--yn-light-red) !important;
}
.yn-td-bordered-green {
  border-left: 4px solid var(--yn-light-green) !important;
}
.yn-td-bordered-yellow {
  border-left: 4px solid var(--yn-light-yellow) !important;
}

/*
.yn-table-scroll {
  display: block;
  empty-cells: show;
}

.yn-table-scroll thead {
  position: relative;
  display: block;
  width: 100%;
  // overflow-y: scroll;
}

.yn-table-scroll tbody {
  display: block;
  position: relative;
  width: 100%;
  overflow-y: scroll;
  height: 400px;
}

.yn-table-scroll tr {
  width: 100%;
  display: flex;
}

.yn-table-scroll td,
.yn-table-scroll th {
  // flex-basis: 50%;
  // flex-grow: 2;
  display: block;
  text-align: left;
}
/*
.yn-table-scroll.yn-small-first-col td:first-child,
.yn-table-scroll.yn-small-first-col th:first-child{
  flex-basis:20%;
  flex-grow:1;
}
.yn-table-scroll.yn-large-second-col td:nth-child(2),
.yn-table-scroll.yn-small-first-col th:nth-child(2){
  flex-basis:20%;
  flex-grow:1;
}
*/

.yn-table,
.yn-table thead,
.yn-table tbody {
  display: flex;
  flex-direction: column;
}

.yn-table {
  margin: 0;
}

.yn-table thead {
  position: sticky;
  background-color: white;
  flex-basis: 40px;
}

.yn-hoverable-row {
  background: white;
}
.yn-hoverable-row:hover {
  background: var(--yn-gray-200);
}

.yn-table-vertical th {
  border-right: 1px solid var(--yn-border-color);
  color: var(--yn-blue-darker);
  padding-right: 1rem;
  padding-bottom: 1rem;
  text-align: right;
  vertical-align: top;
  min-width: 90px;
}

.yn-table-vertical td {
  color: var(--yn-blue-darker);
  border-spacing: 10px !important;
  text-align: left;
  vertical-align: top;
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.yn-label {
  font-weight: bold;
  color: $yn-blue-dark;
}
