.react-tags {
  border: 1px solid #dddde2;
  border-radius: 1px;
  cursor: text;
}

.react-tags.is-focused {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  margin: 0 2px 0 2px;
  padding: 2px 4px;
  border: 1px solid #cfd0d6;
  border-radius: 2px;
  background: #eee;
  color: #005d8c;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #AAA;
  margin-left: 6px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1;
}

.react-tags__search {
  display: inline-block;
  padding: 5px 0 1px 2px;
  margin-bottom: 5px;
  max-width: 100%;
}

.react-tags__search input {
  border: 0;
  outline: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.react-tags__search-input input::placeholder{
  color: transparent;
}