.tab-list {  
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  font-weight: 700;
  color: #999;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.tab-list-active {
  background-color: var(--white);
  color: var(--yn-blue);
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

.tab-list-item:not(.tab-list-active):hover {
  color: var(--yn-blue-darker);
}