@import "_variables.scss";

.yn-main-nav .nav-link {
  padding-right: 0.4rem !important;
  padding-left: 0.4rem !important;
}

.yn-nav-tabs {
  background-color: white;
  padding-top: 2px;
}

.yn-nav-tabs .nav-link {
  margin-bottom: -2px;
  font-weight: 700;
  color: #999;
}

.yn-nav-tabs .nav-link.active {
  background-color: var(--white);
  color: var(--yn-blue);
  border-bottom: transparent;
}

.yn-nav-tabs .nav-link:not(.active):hover {
  border-color: transparent;
  color: var(--yn-blue-darker);
}

.yn-nav-tabs li:first-child {
  margin-left: 10px;
}
