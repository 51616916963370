@import "_variables.scss";
:root {
  --yn-blue: #{$yn-blue};
  --yn-blue-dark: #{$yn-blue-dark};
  --yn-blue-darker: #{$yn-blue-darker};
  --yn-blue-darkest: #{$yn-blue-darkest};
  --yn-blue-light: #{$yn-blue-light};
  --yn-blue-lighter: #{$yn-blue-lighter};
  --yn-body-bg: #{$body-bg};
  --yn-gray-200: #{$yn-gray-200};
  --yn-gray-400: #{$yn-gray-400};
  --yn-gray-500: #{$yn-gray-500};
  --yn-gray-600: #{$yn-gray-600};
  --yn-draggable-background: #{$yn-draggable-background};
  --yn-favorites-group-color: #{$yn-favorites-group-color};
  --yn-success-background-color: #{$yn-success-background-color};
  --yn-danger-background-color: #{$yn-danger-background-color};
  --yn-info-background-color: #{$yn-info-background-color};
  --yn-section-title-height: #{$yn-section-title-height};
  --yn-secondary-nav-height: #{$yn-secondary-nav-height};
  --yn-border-color: #{$yn-border-color};
  --yn-input-focus-border-color: #{$yn-input-focus-border-color};
  --yn-input-focus-box-shadow: #{$yn-input-focus-box-shadow};
  --yn-light-red: #{$yn-light-red};
  --yn-light-green: #{$yn-light-green};
  --yn-light-yellow: #{$yn-light-yellow};
  --yn-footer-height: #{$footer-height};
}
