@import "_variables.scss";
// lg
@media all and (min-width: 992px) {
  .yn-abook-show-header {
    height: $abook-show-header-height-lg;
  }
  .yn-abook-show-list {
    height: calc(100% - #{$abook-show-header-height-lg});
    position: relative;
  }
}
// xl
@media all and (min-width: 1200px) {
  .yn-abook-show-header {
    height: $abook-show-header-height-xl;
  }
  .yn-abook-show-list {
    height: calc(100% - #{$abook-show-header-height-xl});
    position: relative;
  }
}
.yn-abook-initial-active {
  color: var(--yn-blue) !important;
  border-radius: 0.25rem !important;
  border: 1px solid var(--yn-border-color);
  border-left: 3px solid var(--yn-blue);
  border-right: 0
}
