@import "../_variables.scss";
$grays: (
  "100": $yn-gray-100,
  "200": $yn-gray-200,
  "300": $yn-gray-300,
  "400": $yn-gray-400,
  "500": $yn-gray-500,
  "600": $yn-gray-600,
  "700": $yn-gray-700,
  "800": $yn-gray-800,
  "900": $yn-gray-900
);

$light: $yn-gray-100;
$dark: $yn-gray-800;

$theme-colors: (
  "primary": $yn-blue,
  "primary-dark": $yn-blue-dark,
  "gray-light": $yn-gray-200
);

$colors: (
  "gray": $yn-gray-600,
  "gray-dark": $yn-gray-800
);

$body-bg: $body-bg;
$body-color: $body-color;
$link-color: $yn-blue-dark;
$card-border-color:  $yn-gray-300;
$popover-border-color:  $yn-gray-300;

$font-family-sans-serif: $font-family;
$font-size-base: 0.9rem;
$table-hover-bg: $body-bg;

$small-font-size: 90%;

$border-color: $yn-border-color;
$input-border-color: $yn-border-color;

$popover-max-width: 330px;

$enable-shadows: true;
$grid-gutter-width: 10px !default;