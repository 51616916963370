@import '_variables.scss';

.yn-tickets-tree,
.yn-tickets-tree ul {
  margin: 0 0 0 1em; /* indentation */
  padding: 0;
  list-style: none;
  position: relative;
}

.yn-tickets-tree ul {
  margin-left: 0.4em;
} /* (indentation/2) */

.yn-tickets-tree:before,
.yn-tickets-tree ul:before {
  content: '';
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 1px solid;
}

.yn-tickets-tree li {
  margin: 0;
  padding: 0 0.8em; /* indentation + .5em */
  line-height: 1.8; /* default list item's `line-height` */
  position: relative;
}

.yn-tickets-tree li:before {
  content: '';
  display: block;
  width: 7px; /* same with indentation */
  height: 0;
  border-top: 1px solid;
  margin-top: -1px; /* border top width */
  position: absolute;
  top: 1em; /* (line-height/2) */
  left: 0;
}

.yn-tickets-tree li:last-child:before {
  background: white; /* same with body background */
  height: auto;
  top: 1em; /* (line-height/2) */
  bottom: 0;
}
