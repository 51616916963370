.Toastify__toast {
  background-clip: padding-box !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 0.25rem !important;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1) !important;
  -webkit-backdrop-filter: blur(10px) !important;
  backdrop-filter: blur(10px) !important;
  font-family: inherit !important;
  padding: 0 !important;
  color: var(--yn-blue-dark) !important;
  min-height: 30px !important;
}

.Toastify__toast-body {
  margin: 0 !important;
  padding: 0 !important;
  cursor: default !important;
}

.Toastify__toast--error {
  background: var(--danger) !important;
}

.Toastify__close-button {
  position: absolute !important;
  right: 5px !important;
  top: 2px !important;
  color: white !important;
  opacity: 1 !important;
}
