.yn-key-pad {
  display: flex;
  flex-wrap: wrap;
  padding: 0.25em 1.5em;
  justify-content: center;
}

.yn-key-button {
  position: relative;
  flex-basis: calc(40% - 1.8em);
  margin: 0.2em;
  box-sizing: border-box;
}

.yn-key-button::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.yn-key-button .yn-key-circle {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid var(--yn-gray-200);
  padding-top: 20%;
  cursor: pointer;
  font-size: 22px;
  line-height: 1;
}
