@import '../_variables.scss';
@import '_custom-bootstrap.scss';
@import 'bootstrap/scss/bootstrap.scss';

/** fix for custom-control checkbox without label **/
.yn-without-label {
  height: 1rem;
  &:before,
  &:after {
    left: -1.3rem;
  }
}
.form-control {
  color: $yn-blue-darker;
}
.form-control::placeholder {
  color: $yn-gray-600;
}
.form-control.is-invalid {
  background: white;
  padding-right: inherit;
}
.form-control[readonly] {
  background-color: $yn-gray-200;
}
.custom-control-label {
  color: $yn-blue-darker;
  font-weight: 400;
}
.thead {
}
.table td,
.table th {
  padding: 0.25rem;
}
.table th,
.table thead th {
  vertical-align: top;
  color: $yn-blue-darker;
}
.custom-control-input:checked ~ .custom-control-label.half::before {
  color: #fff;
  border-color: var(--yn-input-focus-border-color);
  box-shadow: none;
  background-color: $yn-blue-light;
}
label {
  font-weight: bold;
  color: $yn-blue-dark;
}
.btn-outline-primary {
  background-color: white;
}
.custom-control {
  z-index: auto;
}
.alert-primary {
  color: inherit;
  background-color: $yn-info-background-color;
  border-color: $yn-blue-light;
}
.btn-link:active,
.btn-link:focus {
  box-shadow: unset !important;
}
.btn.btn-link {
  transition: unset !important;
}
