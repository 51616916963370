@import '_variables.scss';

.yn-dropdown {
  .dropdown-toggle {
    padding: 0;
  }
  .dropdown-toggle.nav-link {
    font-weight: 600;
  }

  .status-icon {
    display: inline-block;
    margin: 7px;
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
}

.yn-dropdown .dropdown-menu {
  .dropdown-header {
    padding: 0.5rem 0.9rem;
    font-size: 0.9rem;
    color: var(--gray);
    white-space: nowrap;
  }

  button:disabled {
    color: var(--gray);
  }

  button,
  label,
  a {
    cursor: pointer;
    color: $yn-blue;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  button:hover,
  label:hover,
  a:hover {
    background: var(--light);
    color: $yn-blue-darker;
    text-decoration: none;
  }

  button:active,
  label:active,
  a:active {
    background: $yn-blue;
    color: var(--white);
  }
}

.yn-nav-tabs .yn-dropdown .dropdown-toggle.nav-link {
  padding: 0.5rem 1rem;
  font-weight: 700;
  background: inherit;
}
.yn-nav-tabs .yn-dropdown .dropdown-toggle.nav-link:not(.active) {
  border-color: transparent;
  color: #999;
}
.yn-nav-tabs .yn-dropdown .dropdown-toggle.nav-link.active {
  background-color: white;
}
.yn-nav-tabs .yn-dropdown .dropdown-toggle.nav-link:hover {
  color: $yn-blue-darker;
}
.yn-nav-tabs .yn-dropdown .dropdown-toggle.nav-link:after {
  vertical-align: 0.5em
}